import _ from "lodash";
import { ActionButton } from "../DataTable/ActionButton";

export const RenderActionable = ({ children, permitted, ...actionProps }) => {
  const {
    element,
    path,
    queryKeys,
    pageId,
    info,
    item,
    handleSubmit,
    id,
    __data,
    closeModal,
    parentIds,
    handleSelectRow,
    index,
    stripe,
    currentRepeatedItem,
    dataSource,
    dataEntry,
    viewNameOption,
    views,
    getValues,
  } = actionProps;

  const hasActions = _.has(element, "actionsMap") && _.isObject(element.actionsMap) && !_.isEmpty(element.actionsMap);

  if (hasActions) {
    return (
      <ActionButton
        _key={element?.props?.key}
        path={[path, element?.props?.key].join(".")}
        queryKeys={queryKeys}
        pageId={pageId}
        viewName={info?.viewName}
        item={item}
        element={element}
        action={element?.actionConfig}
        interactionConfig={element?.interactionConfig}
        handleSubmit={handleSubmit}
        tableId={id}
        actionsMap={element?.actionsMap}
        iconButton={false}
        formData={getValues}
        __data={__data}
        closeModal={closeModal}
        parentIds={parentIds}
        disabled={!permitted}
        onSelectRow={handleSelectRow}
        index={index}
        isChainMapped={element?.config?.actionMappingConfig?.isChainMapped}
        actionsKey={element?.config?.actionMappingConfig?.actionsKey}
        isLoadingForEntireChain={element?.config?.actionMappingConfig?.isLoadingForEntireChain}
        stripe={stripe}
        currentRepeatedItem={currentRepeatedItem}
        fromParent={dataSource?.sourceType === "FROM PARENT"}
        dataEntry={dataEntry}
        viewNameOption={viewNameOption}
        views={views}
      >
        {children}
      </ActionButton>
    );
  }

  return children;
};
