import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { IconCheck, IconDotsVertical, IconHome } from "@tabler/icons-react";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setViewBuilder } from "src/features/builder/builderSlice";
import { selectAllComponentsArray, selectBuilderSettings, selectTopLevelComponents } from "src/features/builder/selectors";
import store, { RootState } from "src/store/store";
import ComponentsTreeItem from "./ComponentsTreeItem";
import { ComponentsTreeProps } from "./TreeTypes";

const ComponentsTree: React.FC<ComponentsTreeProps> = ({
  onDiscardEdit,
  handleDeleteItem,
  handleSelectComponent,
  handleCopyItems,
  handlePasteItems,
  onChangePropConfigTreeMenu,
}) => {
  const children = useSelector(selectTopLevelComponents);
  const memoizedChildren = useMemo(() => children, [children]);
  const [isCanvasClicked, setIsCanvasClicked] = useState(false);
  const [lastSelectedItemId, setLastSelectedItemId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedItemsId = useSelector((state: any) => state.builder.selectedItemsId);
  const view: any = useSelector((state: RootState) => state.builder.viewConfiguration);
  const builderSettings = useSelector(selectBuilderSettings);
  const { isSyncTreeWithCanvasEnabled, openStates } = builderSettings;
  const configurationTreeMenuItems = [{ label: "Sync Canvas with Tree", value: "sync" }];

  const handleToggleOpen = useCallback(
    (itemId: string) => {
      dispatch(
        setViewBuilder({
          openStates: { ...openStates, [itemId]: !openStates[itemId] },
          addToHistory: false,
        })
      );
    },
    [dispatch, openStates]
  );

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleConfigurationTreeMenuItemsClick = (item: string) => {
    if (item === "sync") {
      handleSyncCanvasWithTree();
    }
    closeMenu();
  };

  const handleSyncCanvasWithTree = () => {
    if (onChangePropConfigTreeMenu) {
      onChangePropConfigTreeMenu();
    }
  };

  const selectItemsBetween = (startId: string, endId: string, items: any[], openStates: { [key: string]: boolean }): string[] => {
    const selectedIds: string[] = [];
    let isBetween = false;
    let foundBoth = false;

    const traverse = (components: any[]) => {
      for (const component of components) {
        if (foundBoth) return;
        const isOpen = openStates[component.id];

        if (component.id === startId || component.id === endId) {
          selectedIds.push(component.id);
          if (isBetween || startId === endId) {
            foundBoth = true;
            return;
          }
          isBetween = !isBetween;
        } else if (isBetween) {
          selectedIds.push(component.id);
        }

        if (component.children && isOpen) {
          traverse(component.children);
          if (foundBoth) return;
        }
      }
    };

    traverse(items);
    return selectedIds;
  };

  const handleSelectComponentSingleAndMulti = useCallback(
    (selectedItem: any, event?: React.MouseEvent) => {
      setIsCanvasClicked(false);
      const id = selectedItem?.id;
      if (!id) return;

      const currentSelectedItemsId = selectedItemsId;
      let newSelectedItemsId: any = [];

      if (event?.shiftKey && lastSelectedItemId) {
        const itemsBetween = selectItemsBetween(lastSelectedItemId, id, children, openStates);
        newSelectedItemsId = Array.from(new Set([...currentSelectedItemsId, ...itemsBetween]));
        setLastSelectedItemId(id);
      } else if (event?.ctrlKey || event?.metaKey) {
        const isAlreadySelected = currentSelectedItemsId.includes(id);
        if (isAlreadySelected) {
          newSelectedItemsId = currentSelectedItemsId.filter(existingId => existingId !== id);
        } else {
          newSelectedItemsId = [...currentSelectedItemsId, id];
        }

        setLastSelectedItemId(id);
      } else {
        setLastSelectedItemId(id);
        newSelectedItemsId = [id];
      }

      dispatch(setViewBuilder({ selectedItemsId: newSelectedItemsId, addToHistory: false }));
    },
    [selectedItemsId, lastSelectedItemId, children, openStates]
  );

  const handleCanvasClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsCanvasClicked(true);
    dispatch(setViewBuilder({ selectedItemsId: [], addToHistory: false }));

    onDiscardEdit();
  };

  const initializeStates = items => {
    const states = {};

    const initialize = elements => {
      elements?.forEach(item => {
        states[item.id] = true;

        if (item.children && item.children.length > 0) {
          initialize(item.children);
        }
      });
    };

    initialize(items);
    return states;
  };

  useEffect(() => {
    const builderElements = selectAllComponentsArray(store.getState());
    if (builderElements?.length > 0) {
      const updatedOpenStates = initializeStates(builderElements);
      dispatch(
        setViewBuilder({
          openStates: updatedOpenStates,
          addToHistory: false,
        })
      );
    }
  }, [children]);

  return (
    <Box width='100%' flexDirection='column' display='flex' sx={{ position: "relative" }}>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{
          padding: "8px",
          backgroundColor: isCanvasClicked ? theme.palette.primary.light : "rgba(210, 215, 231, 0.10)",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: isCanvasClicked ? theme.palette.primary.light : "rgba(210, 215, 231, 0.25)",
          },
        }}
        onClick={handleCanvasClick}
      >
        <Box display={"flex"} alignItems='center' justifyContent='space-between'>
          <IconHome style={{ marginRight: "8px", color: theme?.palette?.primary[200] }} />
          <Typography sx={{ fontSize: "14px", color: theme?.palette?.primary[200] }}>Canvas </Typography>
        </Box>
        <IconButton onClick={openMenu}>
          <IconDotsVertical width={16} height={16} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          PaperProps={{
            style: {
              padding: 4,
            },
          }}
        >
          {configurationTreeMenuItems.map(item => (
            <MenuItem
              key={item.value}
              sx={{
                fontSize: "12px",
                paddingLeft: "2px",
                paddingRight: "6px",
              }}
              onClick={() => handleConfigurationTreeMenuItemsClick(item.value)}
            >
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <ListItemIcon sx={{ minWidth: "25px !important" }}>
                  {item.value === "sync" && isSyncTreeWithCanvasEnabled && (
                    <IconCheck style={{ color: theme.palette.primary.main }} size={20} />
                  )}
                </ListItemIcon>
                <Typography sx={{ fontSize: "12px" }}>{item.label}</Typography>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Grid>

      <Grid sx={{ paddingLeft: "16px", position: "relative", zIndex: 2 }}>
        {memoizedChildren?.map((item, index) => (
          <ComponentsTreeItem
            key={item.id}
            componentItemId={item}
            children={memoizedChildren}
            handleDeleteItem={handleDeleteItem}
            setIsCanvasClicked={setIsCanvasClicked}
            handleCopyItems={handleCopyItems}
            handleSelectComponentSingleAndMulti={handleSelectComponentSingleAndMulti}
            handlePasteItems={handlePasteItems}
            view={view}
            isCanvasClicked={isCanvasClicked}
            handleSelectComponent={handleSelectComponent}
            isOpen={openStates[item]}
            handleToggleOpen={handleToggleOpen}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default memo(ComponentsTree);
