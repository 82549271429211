import { useEffect, useRef } from "react";
import { useFetchData } from "src/hooks/useFetchData";
import { handleLoad } from "./utils";

const OnTriggerHandler = ({
  element,
  elementKey,
  pageId,
  viewName,
  viewsState,
  viewKey,
  watch,
  produceTrigger,
  __data,
  id,
  info,
  path,
  dataSourceMapKey,
}) => {
  const { refetchDataSource, fetchNextPage } = useFetchData({
    queryKeys: [],
    __data,
    viewId: id,
    limit: element?.configData?.paginationKey || 20,
    dataEntry: element?.configData?.dataEntry,
    pageId,
    options: {
      enabled: element?.configData?.sourceType == "API" || element?.configData?.sourceType == "LIST",
    },
    dataSource: element?.configData?.sourceType == "API" && { ...element?.configData?.source, sourceType: "componentDataSource" },
    endpoint: element?.configData?.sourceType == "LIST" && element?.configData?.selectValues,
    viewName: info?.viewName,
    componentKey: element?.props?.key,
    path: path,
    dataSourceMapKey: dataSourceMapKey,
  });

  const hasLoaded = useRef(false);
  const trigger = watch(`${viewKey}.trigger`, { pageId, viewName });

  useEffect(() => {
    // Perform an action when the event/signal/trigger is triggered and brooked
    const handleTrigger = async () => {
      if (trigger?.type === "refetch") {
        await refetchDataSource?.();
        trigger?.payload?.resolver?.();
      }

      if (trigger?.type === "fetchNextPage") {
        const cursor = trigger?.payload?.currentCursorParam ?? null;
        const isPaginationBar = trigger?.payload?.isPaginationBar ?? false;
        await fetchNextPage?.(cursor, true, isPaginationBar);

        trigger?.payload?.resolver?.();
      }

      if (trigger?.type === "onLoad" && !hasLoaded.current) {
        handleLoad(element, elementKey, pageId, viewName, viewsState, produceTrigger);
        hasLoaded.current = true;
      }
    };

    handleTrigger();
  }, [trigger, viewKey]);

  return null;
};

export default OnTriggerHandler;
